<template>
  <div>
    <v-icon color="orange"
            small
            class="mr-2"
            @click="updateVideoUrl"
    >mdi-pencil
    </v-icon>

    <v-icon color="red"
            small
            @click="alertDeleteDialog"
    > mdi-delete
    </v-icon>
    <v-dialog v-model="dialogDelete" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Delete URL ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogDelete = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click="deleteItem">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  props: ["item", "params", "dataDelete"],
  data() {
    return {
      loading: false,
      dialogDelete: false,
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      }
    };
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    async updateVideoUrl() {
      await this.fetchCurrentList(this.item);
      this.fetchPopUp(true);
    },

    cancelDelete() {
      this.dialogDelete = false
    },
    async alertDeleteDialog() {
      this.dialogDelete = true;
    },

    async deleteItem() {
      let data = {
        _id: this.item._id,
        tv: parseInt(process.env.VUE_APP_TV)
      };
      await this.deleteData({
        vm: this,
        data: data
      });
      this.dialogDelete = false;
      window.location.reload();
      // await this.fetchListResult(this.params)
    },
    ...mapActions("$_videoURL", ["createFormURL","fetchListResult","fetchPopUp","fetchCurrentList","deleteData"]),
  },
};
</script>
